import allNews from "../../all-news.json";
import { hasNames } from "../../utils";
import { debounce } from "lodash";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import Button from "../../components/button";
import Frame from "../../components/frame";
import { HeadMeta } from "../../components/head-meta";
import IconButton from "../../components/icon-button";
import NewsTeaserCard from "../../components/news-teaser-card";

import ChevronDownIcon from "../../images/chevron-down.icon.svg";
import MagnifyingGlassIcon from "../../images/magnifying-glass.icon.svg";

import * as grd from "../../styles/grid.module.scss";
import * as styles from "../../styles/news-template.module.scss";

export const Head = () => {
	return <HeadMeta title="News" />;
};

const NewsIndexPage = ({
	pageContext: { currentPage, currentPageData: data, maxPages },
}) => {
	const [organizedNews, setOrganizedNews] = useState({});
	const [noResults, setNoResults] = useState(false);
	const [currentFilterTag, setCurrentFilterTag] = useState("All");
	const [currentSearch, setCurrentSearch] = useState("");
	const [searchFocused, setSearchFocused] = useState(false);
	const [selectOpen, setSelectOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(false);
	const [hideSelected, setHideSelected] = useState(false);
	const optionsRef = useRef(null);

	const organizeNewsData = () => {
		const temp = { All: allNews };
		allNews.forEach((newsItem) => {
			newsItem.frontmatter.tags.forEach((tag) => {
				if (temp[tag] === undefined) {
					temp[tag] = [newsItem];
				} else {
					temp[tag].push(newsItem);
				}
			});
		});

		return temp;
	};

	const handleTagSelect = (tag) => {
		setCurrentFilterTag(tag);
		setSelectOpen(false);
		setCurrentSearch("");
		if (typeof window !== undefined) {
			window.history.pushState({ tag: tag }, null, "/news/");
		}
	};

	const toggleSelectOpen = () => {
		setSelectOpen((prev) => !prev);
		setSearchFocused(false);
	};

	const toggleSearchInput = () => {
		setSearchFocused((prev) => !prev);
		setCurrentFilterTag("All");
	};

	const handleSearch = (event) => {
		const { value } = event.target;
		setCurrentFilterTag("All");

		setCurrentSearch(value);
		if (typeof window !== undefined) {
			window.history.pushState({ value: value }, null, "/news/");
		}
	};

	// eslint-disable-next-line
	const debouncedSearch = useCallback(debounce(handleSearch, 100), [
		currentFilterTag,
		debounce,
	]);

	const hideCheck = () => {
		if (isMobile && searchFocused) {
			return false;
		} else {
			return true;
		}
	};

	const isFilterOrSearch = () => {
		if (currentFilterTag !== "All" || currentSearch.length > 0) {
			return true;
		} else {
			return false;
		}
	};

	const filterItem = (item) => {
		if (currentSearch.length > 0) {
			if (
				item.excerpt.toLowerCase().includes(currentSearch.toLowerCase()) ||
				item.frontmatter.title
					.toLowerCase()
					.includes(currentSearch.toLowerCase())
			) {
				return true;
			} else {
				return false;
			}
		} else {
			return true;
		}
	};

	useEffect(() => {
		setOrganizedNews(organizeNewsData());

		const handleOffClick = (event) => {
			if (
				optionsRef &&
				optionsRef.current &&
				!optionsRef.current.contains(event.target)
			) {
				setSelectOpen(false);
			}
		};

		const handleEscapePress = (event) => {
			if (event.key === "Escape") {
				setSelectOpen(false);
			}
		};

		const handleResize = () => {
			const width = window.innerWidth;
			if (width <= 576) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		};

		handleResize();

		document.addEventListener("mousedown", handleOffClick);
		document.addEventListener("keydown", handleEscapePress);
		window.addEventListener("resize", handleResize);

		return () => {
			document.removeEventListener("mousedown", handleOffClick);
			document.removeEventListener("keydown", handleEscapePress);
			window.removeEventListener("resize", handleResize);
		};
	}, [optionsRef]);

	useEffect(() => {
		if (
			organizedNews[currentFilterTag] &&
			organizedNews[currentFilterTag].filter(filterItem).length === 0
		) {
			setNoResults(true);
		} else {
			setNoResults(false);
		}
	}, [currentSearch, currentFilterTag, organizedNews]); // eslint-disable-line

	return (
		<Frame>
			<div className={styles.container}>
				<div className={styles.selectWrapper}>
					<button
						className={hasNames(
							styles.selectedTag,
							hideCheck() === false ? styles.smallChevron : ""
						)}
						onClick={toggleSelectOpen}
						aria-haspopup="listbox"
						aria-expanded={selectOpen}>
						<div>
							{hideCheck() && (
								<span>
									{currentFilterTag}&nbsp;(
									{organizedNews[currentFilterTag]?.length})
								</span>
							)}
							<ChevronDownIcon
								className={hideCheck() === false && styles.smallChevron}
							/>
						</div>
					</button>
					{selectOpen && (
						<ul
							className={styles.optionsWrapper}
							ref={optionsRef}
							role="listbox" // eslint-disable-line
							tabIndex={-1}
							aria-activedescendant={
								Object.keys(organizedNews)[currentFilterTag]
							}
							aria-expanded={selectOpen}>
							{Object.keys(organizedNews).map((tag) => (
								<li
									key={tag}
									tabIndex={0}
									role="option" // eslint-disable-line
									className={styles.option}
									onClick={() => handleTagSelect(tag)}
									onKeyDown={() => handleTagSelect(tag)}
									aria-selected={currentFilterTag === tag}>
									{tag}&nbsp;({organizedNews[tag].length})
								</li>
							))}
						</ul>
					)}
				</div>
				<div className={styles.searchWrapper}>
					{!searchFocused ? (
						<IconButton
							className={styles.iconWrapper}
							onClick={toggleSearchInput}
							icon={<MagnifyingGlassIcon className={styles.magnifyingGlass} />}
						/>
					) : (
						<div className={styles.searchInputWrapper}>
							<input
								type="text"
								placeholder="SEARCH"
								onChange={debouncedSearch}
							/>
							<IconButton
								className={styles.iconWrapper}
								onClick={toggleSearchInput}
								icon={
									<MagnifyingGlassIcon className={styles.magnifyingGlass} />
								}
							/>
						</div>
					)}
				</div>
			</div>
			<div className={grd.gridContainer}>
				{!isFilterOrSearch()
					? data.map((news) => {
							return (
								<NewsTeaserCard
									key={"news-page-article-preview-" + news.id}
									className={hasNames(grd.col4, grd.colSm12)}
									tag={news.frontmatter.tags[0]}
									title={news.frontmatter.title}
									date={news.frontmatter.date}
									copy={news.excerpt}
									link={news.path}
									tagFilterFunction={handleTagSelect}
								/>
							);
					  })
					: organizedNews[currentFilterTag]
							.filter(filterItem)
							.map((news) => (
								<NewsTeaserCard
									key={"news-page-article-preview-" + news.id}
									className={hasNames(grd.col4, grd.colSm12)}
									tag={news.frontmatter.tags[0]}
									title={news.frontmatter.title}
									date={news.frontmatter.date}
									copy={news.excerpt}
									link={news.path}
								/>
							))}
			</div>
			{noResults && (
				<div className={styles.noResultsSection}>
					<h2>No Results</h2>
				</div>
			)}
			<div className={styles.loadMoreSection}>
				{currentPage < maxPages && !isFilterOrSearch() && (
					<Button to={`/news/${currentPage + 1}`}>Load More</Button>
				)}
			</div>
		</Frame>
	);
};

export default NewsIndexPage;
